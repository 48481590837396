<template>
  <div v-if="isShow" class="c-modal lg">
    <div class="c-modal__overlay"></div>
    <div class="c-modal__content">
      <div class="c-modal__head">
        <h5 class="c-lead-s">運搬単価一覧</h5>
        <span class="c-modal__head__close" @click="closeModalHandler"></span>
      </div>
      <div class="c-modal__body padding-none">
        <div class="c-inputWrap padding-company-name">
          <dl>
            <dt>{{ this.collectCompany.collectCompanyInfo.name }}運搬単価</dt>
          </dl>
        </div>
          <div class="c-infoList__row head" style="min-width: 100%">
            <div class="md-s txt-center">排出事業場名</div>
            <div v-bind:key="waste.entrustWasteInfoId" v-for="waste in entrust.wasteList">
                <div class="md-s txt-center bottom-none">{{ waste.jwnetItemTypeInfo.name }}</div>
                <div class="md-s txt-center">
                    <span>（単価）</span>
                </div>
            </div>
          </div>
          <div
            v-for="generateStore of entrust.generateStoreInfo"
            :key="generateStore.id"
            class="c-infoList__row list"
            style="min-width: 100%"
          >
          <div class="c-infoList__cell md-s l-doc txt-center">
              {{ generateStore.name }}
          </div>
          <div v-bind:key="waste.entrustWasteInfoId" v-for="waste in entrust.wasteList">
            <div class="c-infoList__cell md-s l-doc txt-center">
              <span>{{ getCollectUniPrice(generateStore.id, waste.entrustWasteInfoId) }}</span>
            </div>
          </div>
          </div>
      </div>
      <div class="c-modal__foot">
        <div class="c-btn primary small" @click="closeModalHandler">閉じる</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .txt-center {
    justify-content: center;
    display: flex;
    flex-shrink: 0;
    width: 24rem;
  }
  .bottom-none {
      border-bottom: none !important;
  }
  .padding-none {
    padding: 0 !important;
  }
  .padding-company-name {
    padding: 3rem 0 0 3rem !important;
  }
</style>

<script>

export default {
  props: {
    isShow: {
      type: Boolean,
    },
    entrust: {
        type: Object
    },
    collectCompany: {
        type: Object
    }
  },
  data() {
    return {
    };
  },
  methods: {
    closeModalHandler() {
      this.$emit("update:isShow", false);
    },
    getCollectUniPrice(generateStoreInfoId, entrustWasteInfoId) {

        const collectUnitPrice = [...this.collectCompany.collectUnitPriceList.filter(e => e.entrustWasteInfoId === entrustWasteInfoId && e.generateStoreInfo.id === generateStoreInfoId)].shift()
        if (collectUnitPrice && collectUnitPrice.unitPrice && collectUnitPrice.priceWasteUnitTypeInfo) {
            return collectUnitPrice.unitPrice + "円/" + collectUnitPrice.priceWasteUnitTypeInfo.name
        }
        return "" 
    }
  },
};
</script>
