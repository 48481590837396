<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="委託契約書詳細" />
    <AppSideMenu />
    <main id="main" class="l-main">
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <div class="l-area" v-if="expireContractMsgs.warning.length || expireContractMsgs.danger.length">
          <span class="c-text icon icon-before warning"
                v-for="(msg, i) of expireContractMsgs.warning" :key="'expire-msg-warning-' + i" >
            {{ msg }}
          </span>
          <span class="c-text icon icon-before danger"
                v-for="(msg, i) of expireContractMsgs.danger" :key="'expire-msg-danger-' + i" >
            {{ msg }}
          </span>
        </div>
        <!-- 基本情報 -->
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">基本情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>契約開始日</label>
              </div>
              <div class="c-inputWrap__items">
                {{ entrust.activeStartDate | dateJpYMDddFormat}}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>契約終了日</label>
              </div>
              <div class="c-inputWrap__items">
                {{ entrust.activeEndDate | dateJpYMDddFormat }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>更新中</label>
              </div>
              <div class="c-inputWrap__items">
                {{ entrust.isUpdate ? "有" : "無" }}
              </div>
            </div>
          </div>
        </div>

        <!-- 担当者情報 -->
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">担当者情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>登録担当者</label>
              </div>
              <div class="c-inputWrap__items">
                {{ entrust.registMemberName | blankText }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>引き渡し担当者</label>
              </div>
              <div class="c-inputWrap__items">
                {{ entrust.generateMemberName | blankText }}
              </div>
            </div>
          </div>
        </div>

        <!-- 受渡確認票 / 一括登録通知配布先 -->
        <div class="l-contents l-box">
          <div class="c-inputWrap">
            <div class="c-checksheetinfo__detail u-w100">
                <div class="c-accordion">
                  <input id="checkSheetInfo" class="c-accordion__cb" type="checkbox" />
                  <div class="c-accordion__head-noborder">
                    <div class="l-box title-wrap u-flex u-mgb0">
                      <h3 class="c-lead icon">受渡確認票 / 一括登録通知配布先</h3>
                      <label class="c-accordion__head__lbl u-mgr0" for="checkSheetInfo"></label>
                    </div>
                  </div>
                  <div class="c-accordion__body u-mgt30">
                    <div class="u-w100 u-mgl0">
                      <div class="c-inputWrap">
                        <div class="c-inputWrap__label">
                          <label>送信フラグ</label>
                        </div>
                        <div class="c-inputWrap__items">
                          {{ entrust.entrustDeliveryConfirmation.isDeliveryConfirmSendFlag ? "送信する" : "送信しない" }}
                        </div>
                      </div>
                      <div class="c-inputWrap">
                        <div class="c-inputWrap__label">
                          <label>送付先email（排出）</label>
                        </div>
                        <div class="c-inputWrap__items">
                          {{ entrust.entrustDeliveryConfirmation.generateDeliveryConfirmMail | blankText }}
                        </div>
                      </div>
                      <div class="c-inputWrap">
                        <div class="c-inputWrap__label">
                          <label>送付先email（運搬）</label>
                        </div>
                        <div class="c-inputWrap__items">
                          {{ entrust.entrustDeliveryConfirmation.collectDeliveryConfirmMail | blankText }}
                        </div>
                      </div>
                      <div class="c-inputWrap">
                        <div class="c-inputWrap__label">
                          <label>送付先email（処分）</label>
                        </div>
                        <div class="c-inputWrap__items">
                          {{ entrust.entrustDeliveryConfirmation.disposalDeliveryConfirmMail | blankText }}
                        </div>
                      </div>
                      <div class="c-inputWrap" v-if="entrust.entrustDeliveryConfirmation.anyMail1 != null">
                        <div class="c-inputWrap__label">
                          <label>送付先email1</label>
                        </div>
                        <div class="c-inputWrap__items">
                          {{ entrust.entrustDeliveryConfirmation.anyMail1 }}
                        </div>
                      </div>
                      <div class="c-inputWrap" v-if="entrust.entrustDeliveryConfirmation.anyMail2 != null">
                        <div class="c-inputWrap__label">
                          <label>送付先email2</label>
                        </div>
                        <div class="c-inputWrap__items">
                          {{ entrust.entrustDeliveryConfirmation.anyMail2 }}
                        </div>
                      </div>
                      <div class="c-inputWrap" v-if="entrust.entrustDeliveryConfirmation.anyMail3 != null">
                        <div class="c-inputWrap__label">
                          <label>送付先email3</label>
                        </div>
                        <div class="c-inputWrap__items">
                          {{ entrust.entrustDeliveryConfirmation.anyMail3 }}
                        </div>
                      </div>
                      <div class="c-inputWrap" v-if="entrust.entrustDeliveryConfirmation.anyMail4 != null">
                        <div class="c-inputWrap__label">
                          <label>送付先email4</label>
                        </div>
                        <div class="c-inputWrap__items">
                          {{ entrust.entrustDeliveryConfirmation.anyMail4 }}
                        </div>
                      </div>
                      <div class="c-inputWrap" v-if="entrust.entrustDeliveryConfirmation.anyMail5 != null">
                        <div class="c-inputWrap__label">
                          <label>送付先email5</label>
                        </div>
                        <div class="c-inputWrap__items">
                          {{ entrust.entrustDeliveryConfirmation.anyMail5 }}
                        </div>
                      </div>
                      <div class="c-inputWrap">
                        <div class="c-inputWrap__label">
                          <label>QR情報1</label>
                        </div>
                        <div class="c-inputWrap__items">
                          {{ entrust.entrustDeliveryConfirmation.qrInfo1 | blankText }}
                        </div>
                      </div>
                      <div class="c-inputWrap">
                        <div class="c-inputWrap__label">
                          <label>QR情報2</label>
                        </div>
                        <div class="c-inputWrap__items">
                          {{ entrust.entrustDeliveryConfirmation.qrInfo2 | blankText }}
                        </div>
                      </div>
                      <div class="c-inputWrap">
                        <div class="c-inputWrap__label">
                          <label>QR情報3</label>
                        </div>
                        <div class="c-inputWrap__items">
                          {{ entrust.entrustDeliveryConfirmation.qrInfo3 | blankText }}
                        </div>
                      </div>
                      <div class="c-inputWrap">
                        <div class="c-inputWrap__label">
                          <label>QR情報4</label>
                        </div>
                        <div class="c-inputWrap__items">
                          {{ entrust.entrustDeliveryConfirmation.qrInfo4 | blankText }}
                        </div>
                      </div>
                      <div class="c-inputWrap">
                        <div class="c-inputWrap__label">
                          <label>QR情報5</label>
                        </div>
                        <div class="c-inputWrap__items">
                          {{ entrust.entrustDeliveryConfirmation.qrInfo5 | blankText }}
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
            </div>
          </div>
        </div>

        <!-- 排出事業者情報 -->
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">排出事業者情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>事業者名</label>
              </div>
              <div v-if="entrust.generateCompanyInfo" class="c-inputWrap__items">
                {{ entrust.generateCompanyInfo.name }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>事業所名</label>
              </div>
              <div class="c-inputWrap__items">
                {{ entrust.generateFactoryInfo.map(item => item.name).join('、') }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>事業場情報</label>
              </div>
              <div class="c-inputWrap__items">
                <span v-for="(store, storeIndex) in entrust.generateStoreInfo" :key="'g-store-' + storeIndex">
                  {{ store.name }}  {{ store.contractStartDate | dateJpYMDddFormat }} ~ {{ store.contractEndDate | dateJpYMDddFormat }}<br>
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- 廃棄物情報 -->
        <div class="l-contents l-box" v-if="entrust.wasteList.length">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">廃棄物情報</h3>
          </div>
          <div
              v-for="(waste, wasteIndex) in entrust.wasteList" :key="'waste-' + wasteIndex">
            <div class="c-inputWrap sub-title">
              <div>
                <label>廃棄物 {{ wasteIndex + 1 }}</label>
              </div>
            </div>
            <div v-for="(label, wasteLabelIndex) in wasteLabels"
                :key="'waste-' + wasteIndex + '-label-' + wasteLabelIndex"
                class="c-inputWrap"
            >
              <div class="c-inputWrap__label">
                <label>{{ label }}</label>
              </div>
              <div v-if="wasteLabelIndex === 0" class="c-inputWrap__items">
                {{ waste.wasteTypeInfo.name }}
              </div>
              <div v-if="wasteLabelIndex === 1" class="c-inputWrap__items">
                {{ waste.manifestTypeInfo.name }}
              </div>
              <div v-if="wasteLabelIndex === 2" class="c-inputWrap__items">
                {{ waste.jwnetItemTypeInfo.name }}
              </div>
              <div v-if="wasteLabelIndex === 3" class="c-inputWrap__items">
                {{ waste.constitutionItemList.map(c => c.name).join("、") }}
              </div>
              <div v-if="wasteLabelIndex === 4" class="c-inputWrap__items">
                {{ waste.cbaItemInfo.name }}
              </div>
              <div v-if="wasteLabelIndex === 5" class="c-inputWrap__items">
                {{ waste.wasteNameInfo.name }}
              </div>
              <div v-if="wasteLabelIndex === 6" class="c-inputWrap__items">
                <span v-if="waste.plansWasteUnitType && waste.plansWasteTermType">
                  {{ waste.plansQuantity | formatFloat }} {{ waste.plansWasteUnitType.name }} / {{ waste.plansWasteTermType.name }}
                </span>
                <span v-else>-</span>
              </div>
              <div v-if="wasteLabelIndex === 7" class="c-inputWrap__items">
                <span v-if="waste.limitWasteUnitType && waste.limitWasteTermType">
                  {{ waste.limitQuantity | formatFloat }} {{ waste.limitWasteUnitType.name }} / {{ waste.limitWasteTermType.name }}
                </span>
                <span v-else>-</span>
              </div>
              <div v-if="wasteLabelIndex === 8" class="c-inputWrap__items">
                {{ waste.shapeTypeList.map(item => item.name).join('、') | blankText }}
              </div>
              <div v-if="wasteLabelIndex === 9" class="c-inputWrap__items">
                {{ waste.disposalWayTypeList.map(d => d.name).join("、") | blankText }}
              </div>
              <div v-if="wasteLabelIndex === 10" class="c-inputWrap__items">
                {{ waste.processInfo | blankText }}
              </div>
              <div v-if="wasteLabelIndex === 11" class="c-inputWrap__items l-area">
                <a v-if="waste.wdsFileName" class="c-text text-link c-entrustText__text" @click="downloadWDS(waste.entrustWasteInfoId, waste.wdsFileName)">
                  {{ waste.wdsFileName }}
                </a>
                <span v-else>-</span>
              </div>
              <div v-if="wasteLabelIndex === 12" class="c-inputWrap__items">
                {{ waste.activeStartDate | dateJpYMDddFormat }}
              </div>
              <div v-if="wasteLabelIndex === 13" class="c-inputWrap__items">
                {{ waste.activeEndDate | dateJpYMDddFormat }}
              </div>
            </div>
          </div>
        </div>

        <!-- 運搬委託契約先情報 -->
        <div class="l-contents l-box" v-if="entrust.entrustCollectCompanyInfo.length">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">運搬委託契約先情報</h3>
          </div>
          <div
               v-for="(collectCompany, i) in entrust.entrustCollectCompanyInfo" :key="'collector-' + i">
            <div class="c-inputWrap sub-title">
              <div>
                <label>運搬事業者 {{ i + 1 }}</label>
              </div>
            </div>
            <div
              v-for="(label, index) in collectorLabels"
              :key="'collector-' + i + '-label-' + index"
              class="c-inputWrap"
            >
              <div class="c-inputWrap__label">
                <label>{{ label }}</label>
              </div>
              <div v-if="index === 0" class="c-inputWrap__items">
                {{ collectCompany.collectCompanyInfo.name }}
              </div>
              <div v-if="index === 1 && !isDisposer" class="c-inputWrap__items">
                <div v-if="collectCompany.collectLicenceInfo.length">
                  <span
                      v-for="(item, index) in collectCompany.collectLicenceInfo"
                      :key="'collect-licence-' + index"
                  >
                    <router-link :to="`/licence/collect/${item.licenceId}`" class="c-text text-link">
                      {{ item.licenceNo }}
                    </router-link>
                    <span v-if="index < collectCompany.collectLicenceInfo.length - 1">、</span>
                  </span>
                </div>
                <div v-if="collectCompany.commonCollectLicenceInfo.length">
                  <span
                      v-for="(item, index) in collectCompany.commonCollectLicenceInfo"
                      :key="'common-collect-licence-' + index"
                  >
                    <router-link :to="`/licence/collect/common/${item.licenceId}`" class="c-text text-link">
                      {{ item.licenceNo }}
                    </router-link>
                    <span v-if="index < collectCompany.commonCollectLicenceInfo.length - 1">、</span>
                  </span>
                </div>
              </div>
              <div v-if="index === 1 && isDisposer" class="c-inputWrap__items">
                <div v-if="collectCompany.collectLicenceInfo.length">
                  <span
                      v-for="(item, index) in collectCompany.collectLicenceInfo"
                      :key="'collect-licence-' + index"
                  >
                      {{ item.licenceNo }}
                    <span v-if="index < collectCompany.collectLicenceInfo.length - 1">、</span>
                  </span>
                </div>
                <div v-if="collectCompany.commonCollectLicenceInfo.length">
                  <span
                      v-for="(item, index) in collectCompany.commonCollectLicenceInfo"
                      :key="'common-collect-licence-' + index"
                  >
                      {{ item.licenceNo }}
                    <span v-if="index < collectCompany.commonCollectLicenceInfo.length - 1">、</span>
                  </span>
                </div>
              </div>
              <div v-if="index === 2" class="c-inputWrap__items">
                <span>
                  {{ collectCompany.contractStartDate | dateJpYMDddFormat }}
                </span>
              </div>
              <div v-if="index === 3" class="c-inputWrap__items">
                <span>
                  {{ collectCompany.contractEndDate | dateJpYMDddFormat }}
                </span>
              </div>
              <div v-if="index === 4" class="c-inputWrap__items">
                <span
                  class="c-badge-black"
                  v-if="collectCompany.isAutoUpdate"
                  >自動更新
                </span>
                <span v-else>-</span>
              </div>
              <div v-if="index === 5" class="c-inputWrap__items">
                <div v-if="collectCompany.collectUnitPriceList && collectCompany.collectUnitPriceList.filter(cup => cup.priceWasteUnitTypeInfo && cup.unitPrice).length > 0" style="cursor: pointer;" class="c-text text-link"
                @click="showCollectUnitListModal(i)">
                  運搬単価一覧
                </div>
                <span v-else>-</span>
              </div>
              <div v-if="index === 6" class="c-inputWrap__items">
                <template v-if="collectCompany.entrustCollectContractFileList.length">
                  <template v-if="isCollector">
                    <span v-for="collectFile in collectCompany.entrustCollectContractFileList"
                      :key="collectFile.id"
                      style="display: flex">
                      {{ collectFile.contractFileName }}
                    </span>
                  </template>
                  <template v-else>
                    <a v-for="collectFile in collectCompany.entrustCollectContractFileList"
                      :key="collectFile.id"
                      class="c-text text-link c-entrustText__text" @click="downloadCollectContractFile(collectFile.id, collectFile.contractFileName)">
                      {{ collectFile.contractFileName }}
                    </a>
                  </template>
                </template>
                <span v-else>-</span>
              </div>
              <div v-if="index === 7" class="c-inputWrap__items l-area">
                <span>
                  {{ collectCompany.remarks | blankText }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- 処分委託契約先情報 -->
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">処分委託契約先情報</h3>
          </div>
          <div>
            <div
              v-for="(label, index) in disposalLabels"
              :key="'disposal-' + '-label-' + index"
              class="c-inputWrap"
            >
              <div class="c-inputWrap__label">
                <label>{{ label }}</label>
              </div>
              <div v-if="index === 0 && entrust.entrustDisposalCompanyInfo && entrust.entrustDisposalCompanyInfo.disposalCompanyInfo" class="c-inputWrap__items">
                {{ entrust.entrustDisposalCompanyInfo.disposalCompanyInfo.name }}
              </div>
              <div v-if="index === 1 && !isDisposer" class="c-inputWrap__items">
                <div v-if="entrust.entrustDisposalCompanyInfo.disposalLicenceInfo && entrust.entrustDisposalCompanyInfo.disposalLicenceInfo.length">
                  <span
                    v-for="(item, index) in entrust.entrustDisposalCompanyInfo.disposalLicenceInfo"
                    :key="'disposal-licence-' + index"
                  >
                    <router-link :to="`/licence/disposal/${item.licenceId}`" class="c-text text-link">
                      {{ item.licenceNo }}
                    </router-link>
                    <span v-if="index < entrust.entrustDisposalCompanyInfo.disposalLicenceInfo.length - 1">、</span>
                  </span>
                </div>
                <div v-if="entrust.entrustDisposalCompanyInfo.commonDisposalLicenceInfo && entrust.entrustDisposalCompanyInfo.commonDisposalLicenceInfo.length">
                  <span
                    v-for="(item, index) in entrust.entrustDisposalCompanyInfo.commonDisposalLicenceInfo"
                    :key="'common-disposal-licence-' + index"
                  >
                    <router-link :to="`/licence/disposal/common/${item.licenceId}`" class="c-text text-link">
                      {{ item.licenceNo }}
                    </router-link>
                    <span v-if="index < entrust.entrustDisposalCompanyInfo.commonDisposalLicenceInfo.length - 1">、</span>
                  </span>
                </div>
              </div>
              <div v-if="index === 1 && isDisposer" class="c-inputWrap__items">
                <div v-if="entrust.entrustDisposalCompanyInfo.disposalLicenceInfo && entrust.entrustDisposalCompanyInfo.disposalLicenceInfo.length">
                  <span
                    v-for="(item, index) in entrust.entrustDisposalCompanyInfo.disposalLicenceInfo"
                    :key="'disposal-licence-' + index"
                  >
                      {{ item.licenceNo }}
                    <span v-if="index < entrust.entrustDisposalCompanyInfo.disposalLicenceInfo.length - 1">、</span>
                  </span>
                </div>
                <div v-if="entrust.entrustDisposalCompanyInfo.commonDisposalLicenceInfo && entrust.entrustDisposalCompanyInfo.commonDisposalLicenceInfo.length">
                  <span
                    v-for="(item, index) in entrust.entrustDisposalCompanyInfo.commonDisposalLicenceInfo"
                    :key="'common-disposal-licence-' + index"
                  >
                      {{ item.licenceNo }}
                    <span v-if="index < entrust.entrustDisposalCompanyInfo.commonDisposalLicenceInfo.length - 1">、</span>
                  </span>
                </div>
              </div>
              <div v-if="index === 2" class="c-inputWrap__items">
                {{ entrust.entrustDisposalCompanyInfo.contractStartDate | dateJpYMDddFormat }}
              </div>
              <div v-if="index === 3" class="c-inputWrap__items">
                {{ entrust.entrustDisposalCompanyInfo.contractEndDate | dateJpYMDddFormat }}
              </div>
              <div v-if="index === 4" class="c-inputWrap__items">
                <span
                  class="c-badge-black"
                  v-if="entrust.entrustDisposalCompanyInfo.isAutoUpdate"
                  >自動更新
                </span>
                <span v-else>-</span>
              </div>
              <div v-if="index === 5" class="c-inputWrap__items">
                <div v-if="entrust.entrustDisposalCompanyInfo.disposalUnitPriceList && entrust.entrustDisposalCompanyInfo.disposalUnitPriceList.filter(dup => dup.priceWasteUnitTypeInfo && dup.unitPrice).length > 0" style="cursor: pointer;" class="c-text text-link"
                @click="showDisposalUnitListModal()">
                  処分単価一覧
                </div>
                <span v-else>-</span>
              </div>
              <div v-if="index === 6" class="c-inputWrap__items">
                <template v-if="entrust.entrustDisposalCompanyInfo.entrustDisposalContractFileList && entrust.entrustDisposalCompanyInfo.entrustDisposalContractFileList.length">
                  <template v-if="isCollector">
                    <span v-for="disposalFile in entrust.entrustDisposalCompanyInfo.entrustDisposalContractFileList"
                    :key="disposalFile.id"
                    style="display: flex">
                    {{ disposalFile.contractFileName }}
                    </span>
                  </template>
                  <template v-else>
                    <a v-for="disposalFile in entrust.entrustDisposalCompanyInfo.entrustDisposalContractFileList"
                      :key="disposalFile.id"
                      class="c-text text-link c-entrustText__text" @click="downloadDisposalContractFile(disposalFile.id, disposalFile.contractFileName)">
                      {{ disposalFile.contractFileName }}
                    </a>
                  </template>
                </template>
                <span v-else>-</span>
              </div>
              <div v-if="index === 7" class="c-inputWrap__items">
                <span>
                  {{ entrust.entrustDisposalCompanyInfo.remarks | blankText }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- 経路情報 -->
        <div class="l-contents l-box" v-if="entrust.entrustCollectCompanyInfo.length">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">経路情報</h3>
          </div>
          <div
            v-for="(item, sectionIndex) in entrust.entrustCollectCompanyInfo"
            :key="'section-' + sectionIndex"
          >
            <div class="c-inputWrap sub-title">
              <div>
                <label>区間{{ item.sectionNo }}</label>
              </div>
            </div>
            <div
              v-for="(label, index) in sectionLabels"
              :key="'section-' + index"
              class="c-inputWrap"
            >
              <div class="c-inputWrap__label">
                <label>{{ label }}</label>
              </div>
              <div v-if="index === 0
                && entrust.generateStoreInfo.length" class="c-inputWrap__items">
                {{ sectionIndex === 0 ? entrust.generateStoreInfo.map(s => s.name).join('、') : entrust.entrustCollectCompanyInfo[sectionIndex-1].destinationInfo.name | blankText }}
              </div>
              <div v-if="index === 1" class="c-inputWrap__items">
                {{ item.collectFactoryInfo ? item.collectFactoryInfo.name : '-' }}
                <div v-if="entrust.wasteList && entrust.wasteList[0].wasteTypeInfo.id === wasteType.INDUSTRIAL_WASTE">JWNETID(加入者番号): {{ item.collectFactoryInfo ? item.collectFactoryInfo.jwnetId : '-' }}</div>
              </div>
              <div v-if="index === 2" class="c-inputWrap__items">
                {{ item.collectWayTypeInfo ? item.collectWayTypeInfo.name : '-' }}
              </div>
              <div v-if="index === 3" class="c-inputWrap__items">
                <template v-if="item.destinationInfo.destinationTypeInfo.id === 1">
                  <template>
                    {{ item.destinationInfo ? item.destinationInfo.name : '-' }}<br>
                    JWNETID(加入者番号): {{ item.destinationInfo ? item.destinationInfo.jwnetId : '-' }}<br>
                    事業場コード: {{ item.destinationInfo ? item.destinationInfo.storeCode : '-' }}<br>
                  </template>
                </template>

                <template v-if="item.destinationInfo.destinationTypeInfo.id === 2">
                  {{ entrust.entrustDisposalCompanyInfo.destinationInfo ? entrust.entrustDisposalCompanyInfo.destinationInfo.name : '-' }}<br>

                    JWNETID(加入者番号): {{ entrust.entrustDisposalCompanyInfo.destinationInfo ? entrust.entrustDisposalCompanyInfo.destinationInfo.jwnetId : '-' }}<br>
                    事業場コード: {{ entrust.entrustDisposalCompanyInfo.destinationInfo ? entrust.entrustDisposalCompanyInfo.destinationInfo.storeCode : '-' }}<br>

                </template>
              </div>
            </div>
          </div>
        </div>

        <!-- 最終処分場情報 ======================================== -->
        <div class="l-contents l-box" v-if="entrust.entrustDisposalCompanyInfo.FinalDisposalSiteList && entrust.entrustDisposalCompanyInfo.FinalDisposalSiteList.length">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">最終処分場情報</h3>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>最終処分場</label>
            </div>
            <div class="c-disposalSiteList__detail">

              <template v-for="item in entrust.entrustDisposalCompanyInfo.FinalDisposalSiteList">
                <div class="c-accordion" :key="item.finalDisposalSiteId">
                  <input :id="'disposalSiteList_'+item.finalDisposalSiteId" class="c-accordion__cb" type="checkbox" />
                  <div class="c-accordion__head">
                    <label class="c-accordion__head__lbl" :for="'disposalSiteList_'+item.finalDisposalSiteId"></label>
                    <div class="c-accordion__head__text">{{item.finalDisposalSiteName}}</div>
                  </div>
                  <div class="c-accordion__body">

                    <dl class="c-disposalSiteList__detail__item">
                      <div class="address">
                        <dt>住所</dt>
                        <dd>〒{{item.addressInfo.zipCode}} {{ item.addressInfo.prefecturesName }} {{ item.addressInfo.address }} {{ item.addressInfo.buildingName }}</dd>
                      </div>
                      <div class="tel">
                        <dt>電話番号</dt>
                        <dd>{{ item.tel }}</dd>
                      </div>
                      <div class="code">
                        <dt>事業所コード</dt>
                        <dd>{{ item.finalDisposalStoreCode }}</dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <FloatButtons>
        <div class="left">
          <router-link to="/entrust" class="c-btn secondary small back">一覧へ戻る</router-link>
        </div>
        <div v-if="isCba" class="center">
          <router-link :to="`/entrust/edit/${$route.params.id}`" class="c-btn secondary small">
            編集
          </router-link>
        </div>
      </FloatButtons>
      <CollectUnitListModal
        :isShow.sync="isCollectUnitListModalShow"
        :entrust="entrust"
        :collectCompany="currentCollectCompany"
      />
      <DisposalUnitListModal
        :isShow.sync="isDisposalUnitListModalShow"
        :entrust="entrust"
      />
    </main>
    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "@/const";
import { saveAs } from "file-saver";
import moment from "moment";
import * as filters from "@/filter";
import { DOMAIN } from "@/const/index";
import {CONTRACT_END_ALERT_INTERVAL} from "@/const/dateLimit";
import FloatButtons from "@/components/layout/FloatButtons";
import CollectUnitListModal from "./components/CollectUnitListModal.vue";
import DisposalUnitListModal from "./components/DisposalUnitListModal.vue";

export default {
  name: "entrust-detail",
  components: {
    FloatButtons,
    CollectUnitListModal,
    DisposalUnitListModal,
  },
  data() {
    return {
      entrust: {
        generateCompanyInfo: {},
        generateFactoryInfo: [],
        generateStoreInfo: [],
        wasteList: [],
        entrustCollectCompanyInfo: [],
        entrustDisposalCompanyInfo: {},
        registMemberName: null,
        generateMemberName: null,
        entrustDeliveryConfirmation: {},
      },
      currentCollectCompany: {},
      wasteType: DOMAIN.WASTE_TYPE,
      collectorLabels: [
        "運搬事業者",
        "運搬許可証番号",
        "契約開始日",
        "契約終了日",
        "契約自動更新",
        "運搬単価",
        "契約書PDF",
        "備考",
      ],
      disposalLabels: [
        "処分事業者",
        "処分許可証番号",
        "契約開始日",
        "契約終了日",
        "契約自動更新",
        "処分単価",
        "契約書PDF",
        "備考",
      ],
      wasteLabels: [
        "廃棄物区分",
        "マニフェスト区分",
        "廃棄物種類",
        "構成種類",
        "CBA品目",
        "廃棄物名称",
        "予定数量",
        "上限数量",
        "荷姿",
        "処分方法",
        "適正処理に必要な情報",
        "WDS",
        "利用開始日",
        "利用終了日",
      ],
      sectionLabels: [
        "積み込み場所",
        "運搬会社/事務所",
        "運搬方法",
        "運搬先",
      ],
      errMsgs: [],
      isCba: this.$store.getters["userInfo/isCba"],
      isDisposer: this.$store.getters["userInfo/isDisposer"],
      isCollector: this.$store.getters["userInfo/isCollector"],
      expireContractMsgs: {
        warning: [],
        danger: [],
      },
      isCollectUnitListModalShow: false,
      isDisposalUnitListModalShow: false,
    };
  },
  mounted() {
    // 委託契約書取得のAPI
    axios
      .get(API_URL.ENTRUST.DETAIL + this.$route.params.id)
      .then((res) => {
        this.entrust = res.data;
        const today = moment();

        // 区間のソート
        this.entrust.entrustCollectCompanyInfo = this.entrust.entrustCollectCompanyInfo.sort((a,b) => {
          if( a.sectionNo < b.sectionNo ) return -1;
          if( a.sectionNo > b.sectionNo ) return 1;
          return 0;
        });

        // 運搬委託契約：契約期限アラート
        this.entrust.entrustCollectCompanyInfo.forEach(collectCompany => {
          const collectContractEndDate = moment(collectCompany.contractEndDate);
          const collectDiff = collectContractEndDate.diff(today, "days");
          if (collectDiff < 0) {
            const danger = `${collectCompany.collectCompanyInfo.name} との運搬委託契約は ${filters.dateJpMDddFormat(collectCompany.contractEndDate)} で無効となりました`;
            this.expireContractMsgs.danger.push(danger);
          } else if (collectDiff < CONTRACT_END_ALERT_INTERVAL) {
            let warning = `${collectCompany.collectCompanyInfo.name} との運搬委託契約は ${filters.dateJpMDddFormat(collectCompany.contractEndDate)} `;
            warning += collectCompany.isAutoUpdate ? 'に更新されます（自動更新有り）' : 'で無効となります（自動更新無し）';
            this.expireContractMsgs.warning.push(warning);
          }
        });

        // 処分委託契約：契約期限アラート
        const disposalContractEndDate = moment(this.entrust.entrustDisposalCompanyInfo.contractEndDate);
        const disposalDiff = disposalContractEndDate.diff(today, "days");
        if (disposalDiff < 0) {
          const danger = `${this.entrust.entrustDisposalCompanyInfo.disposalCompanyInfo.name} との処分委託契約は ${filters.dateJpMDddFormat(this.entrust.entrustDisposalCompanyInfo.contractEndDate)} で無効となりました`;
          this.expireContractMsgs.danger.push(danger);
        } else if (disposalDiff < CONTRACT_END_ALERT_INTERVAL) {
          let warning = `${this.entrust.entrustDisposalCompanyInfo.disposalCompanyInfo.name} との処分委託契約は ${filters.dateJpMDddFormat(this.entrust.entrustDisposalCompanyInfo.contractEndDate)} `;
          warning += this.entrust.entrustDisposalCompanyInfo.isAutoUpdate ? 'に更新されます（自動更新有り）' : 'で無効となります（自動更新無し）';
          this.expireContractMsgs.warning.push(warning);
        }

      })
      .catch((err) => {
        this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
      });
  },
  methods: {
    // WDSダウンロード
    downloadWDS(wasteInfoId, wdsFileName) {
      axios
        .get(API_URL.ENTRUST.WASTE_INFO_WDS_DOWNLOAD + wasteInfoId, {
          responseType: "blob",
        })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type
          });
          saveAs(blob, wdsFileName);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 運搬委託契約書ダウンロード
    downloadCollectContractFile(entrustCollectContractFileId, contractFileName) {
      axios
        .get(API_URL.ENTRUST.COLLECT_CONTRACT_FILE_DOWNLOAD + entrustCollectContractFileId, {
          responseType: "blob",
        })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type
          });
          saveAs(blob, contractFileName);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 処分委託契約書ダウンロード
    downloadDisposalContractFile(entrustDisposalContractFileId, contractFileName) {
      axios
        .get(API_URL.ENTRUST.DISPOSAL_CONTRACT_FILE_DOWNLOAD + entrustDisposalContractFileId, {
          responseType: "blob",
        })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type
          });
          saveAs(blob, contractFileName);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 「運搬単価一覧」ボタンを押す時
    showCollectUnitListModal(entrustCollectIndex) {
      this.currentCollectCompany = this.entrust.entrustCollectCompanyInfo[entrustCollectIndex]
      this.isCollectUnitListModalShow = true
    },
    showDisposalUnitListModal() {
      this.isDisposalUnitListModalShow = true
    }
  }
};
</script>
<style scoped lang="scss">
.sub-title {
  border-bottom: 1px solid #cbd0d8;
  margin: 2rem 0;
  font-size: 1.6rem;
}

.c-disposalSiteList__detail {
  flex: 1;

  &__item {
    div {
      + div {
        margin-top: 5px;
      }
      display: flex;

      dt {
        width: 10vw;
        flex-shrink: 0;
      }
    }
  }
}

.u-flex{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.u-w100{
  width: 100%;
}

.u-mgl0{
  margin-left: 0rem;
}

.u-mgb0{
  margin-bottom: 0rem;
}

.u-mgt30{
  margin-top: 3rem;
}

.u-mgr0{
  margin-right: 0rem;
}

</style>
